//  ================================================
//  Content
//  ================================================

.content {
  flex: 1;
  min-width: 0;
  background: $grey50;
  padding-top: 20px;

  &-wrapper {
    position: relative;
  }

  &-title {
    margin-bottom: 12px;
  }

  &-filter-buttons {
    bottom: 25px;
    margin: auto;
    position: fixed;
    left: 20px;
    z-index: 1;
    display: flex;
    border-radius: 20px;
    overflow: hidden;

    .button {
      padding-left: 20px;
      padding-right: 20px;
      min-width: 0;
      border-radius: 0;

      i {
        font-size: 16px;
        color: white;
        margin-right: 5px;
      }
    }

    .button + .button {
      margin-left: 1px;
    }
  }

  &-header-hotel {
    &, button {
      @extend %is-font-medium;
    }
  }

  &-header {
    padding-left: 24px;
    padding-right: 24px;
    margin-bottom: 24px;
  }

  &-checkout-ical {
    margin-bottom: 20px;
    text-align: center;
  }

  &-sort {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .Select {
      margin-left: 12px;
    }
  }

  @include media-breakpoint-down(md) {
    &-wrapper {
      padding-bottom: 150px;
    }

    &-header-hotel {
      display: block;

      .content {
        &-header-show_map {
          display: none;
        }

        &-header-sort {
          display: none;
        }

        &-title {
          margin-left: 20px;
          padding-bottom: 20px;
        }
      }
    }
  }


  @include media-breakpoint-up(md) {
    padding: 20px 32px 50px;
    background: transparent;
    max-width: $content-width;
    margin-left: auto;
    margin-right: auto;

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0;

      //TODO: refactore

      .button i {
        margin-left: 5px;
      }

      h2 {
        margin-bottom: 0;
        margin-right: auto;
      }

      &-reverse {
        flex-direction: row-reverse;
      }

      &-hotel {
        .button {
          margin-left: 15px;
        }

        .content-header-sort:before {
          content: "|";
          margin-right: 15px;
        }
      }

      &-sort {
        display: flex;
        align-items: center;
        margin-left: 15px;

        .Select {
          margin-left: 10px;
        }
      }

      &-show_map {
        font-weight: bold;

        i {
          font-size: 12px;
          color: $body;
          margin-right: 5px;
        }
      }
    }

    &-info {
      margin-top: -5px;
    }
  }


  &-filter-buttons {
    transform: translateX(-50%);
    left: 50%;
  }

  @include media-breakpoint-up(lg) {
    &-filter-buttons {
      display: none;
    }
  }
}
