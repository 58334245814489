@import "resources.scss";
.breadcrumbs {
  display: none;
  @extend %reset;

  @include media-breakpoint-up(md) {
    display: flex;

    &-item {
      position: relative;
      display: flex;
      align-items: center;

      &-active {
        font-weight: 500;
        cursor: pointer;
      }

      i {
        color: $light-gray;
      }

      &:last-child i {
        display: none;
      }
    }

    &-link {
      padding: 0 5px;
      &-is-active {
        cursor: pointer;
      }
      &-is-disabled {
        cursor: not-allowed;
      }
    }
  }

  @include media-breakpoint-up(xl) {
    margin-left: auto;
  }
}
