@import "resources.scss";
.search-bar {
  position: relative;
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &-inner {
    padding: 85px 24px 34px;

    &:nth-child(2) {
      border-top: 1px solid $border-gray;
      padding-top: 48px;
      padding-bottom: 52px;
    }
  }

  &-button {
    padding: 20px 0 48px;
  }

  &-spacer {
    height: 36px;

    &.is--small {
      height: 12px;
    }
  }

  @include media-breakpoint-up(md) {
    max-width: 480px;
    z-index: 300;

    &-inner {
      padding: 40px 48px 34px;
    }

    .logo {
      margin-bottom: 48px;
    }
  }
}
