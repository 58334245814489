@import "resources.scss";
.lp-scroll-up-btn {
  position:fixed;
  right:10px;
  bottom:80px;
  cursor:pointer;
  width:30px;
  height:30px;
  background-color:#000;
  text-indent:-9999px;
  -webkit-border-radius:60px;
  -moz-border-radius:60px;
  border-radius:60px;
  span {
    position:absolute;
    top:50%;
    left:50%;
    margin-left:-6px;
    margin-top:-10px;
    height:0;
    width:0;
    border:6px solid transparent;
    border-bottom-color:#ffffff;
  }
  &:hover {
    background-color:#e74c3c;
    opacity:1;filter:"alpha(opacity=100)";
    -ms-filter:"alpha(opacity=100)";
  }
}