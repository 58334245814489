@import "resources.scss";
.logo {
  display: flex;
  align-items: center;
  height: 100%;

  &-co {
    height: 100%;
    width: auto;
  }

  &-main {
    width: 191px;
    height: 24px;
  }

  &-brand {
    max-width: 150px;
    max-height: 70px;
  }

  &.in--header {
    cursor: pointer;

    .logo-main {
      margin-left: 24px;
    }
  }

  @include media-breakpoint-up(md) {
    margin-bottom: 42px;
    margin-top: 14px;
    display: block;
    height: auto;

    &-main {
      width: 134px;
      height: 56px;
    }

    &-co {
      width: 182px;
      height: 38px;
      position: absolute;
      top: 0;
      left: 0;
    }

    &-brand {
      max-height: 72px;
      max-width: 200px;
    }

    &.in--header {
      display: flex;
      align-items: center;
      height: 100%;
      margin: 0;
      left: -20px;
      position: relative;

      .logo {
        &-brand {
          max-width: 150px;
          max-height: 70px;
        }

        &-co {
          height: 100%;
          width: auto;
          position: relative;
        }
      }
    }
  }
}
