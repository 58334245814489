@import "resources.scss";
.tcp-search-bar-route-input {
  position: relative;
  background: white;

  input, button {
    @extend %is-font-large;

    color: $dark-gray;

    @include input-placeholder {
      color: $gray;
      font-weight: normal;
    }
  }

  &-widget {
    input, button {
      margin-left: 12%;
      margin-right: 3%;
      padding: 14px 0 14px 50px;
      width: 88%;
    }

    i {
      font-size: 18px;
      vertical-align: middle;
      margin-right: 5px;
    }

    .icon-to {
      position: relative;
    }

    .icon-from {
      position: relative;
      top: 3px;
    }

    .tcp-search-bar-route-input {
      &-from {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;

        input, button {
          border-bottom: 1px solid $lighter-gray;
        }
      }

      &-to {
        input, botton {
          border-top: 0;
        }
      }

      &-label {
        position: absolute;
        left: 10px;
        top: 14px;
        font-weight: bold;
        max-width: 80px;
        text-emphasis: e;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      &-connector {
        @include position(30px n n 17px, absolute, 100);

        border-left: 1px dashed $lighter-gray;
        height: 30px;
        width: 0px;
        transform: translateX(2px);
      }
    }
  }

  &-slideout {

    i {
      display: none;
    }

    input {
      @extend %is-font-extralarge;

      width: 100%;
      padding: 14px 0 14px 7px;
    }

    .tcp-search-bar-route-input {
      &-label {
        display: none
      }

      &-connector {
        display: none;
      }
    }

    @include media-breakpoint-up(sm) {
      padding: 55px 15px 40px;
    }
  }
}
