@import "resources.scss";

  .tcp-sidebar-route {
    margin-bottom: 5px;
    display: flex;

    &-icon {
      width: 15px;
      margin-right: 8px;
      font-size: 15px;
      &-centered {
        padding-top: 5px;
      }
    }

    &-title {
      @extend %is-font-medium;
      &-label {
        font-weight: bold;
        font-size: 10px;
        color: $gray;
      }
    }

    &--spacing {
      margin-bottom: 15px;
    }
  }
