@import "resources.scss";
.tcp-trip-shuttle-type-selector {

  &:first-child {
    margin-bottom: 12px;
  }

  &-transit-title {
    @extend %is-font-medium;
    padding-bottom: 8px;
  }

  &-select {
    margin: 5px 0 5px 0px;
    font-weight: bold;
  }
}
