@import "resources.scss";
.searchbar-slide-out {
  @include tn(opacity visibility);

  opacity: 0;
  visibility: hidden;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: -1;
  background: white;
  padding: 0 $gutter $gutter;

  &.is--open {
    opacity: 1;
    visibility: visible;
    z-index: $z1;
    height: 100%;
  }

  &.is--passenger,
  &.is--route {
    overflow-y: auto;
  }

  &.is--route {
    .label {
      margin-bottom: 8px;
    }
  }

  @include media-breakpoint-up(md) {
    position: absolute;
    margin-top: 30px;
    left: calc(100% + 30px);
    padding: 24px 24px;
    max-width: 360px;
    max-height: 500px;
    height: auto;
    box-shadow: $shadow08;
    border-radius: 8px;

    &.is--open {
      height: auto;
    }

    &.is--route {
      top: 150px;
    }

    &.is--datetime {
      top: 230px;
    }

    &.is--passenger {
      top: 280px;
      min-height: 440px;
    }
  }
}
