.sidebar-trip {
  @include tn(opacity visibility, 150ms 150ms);

  position: fixed;
  top: 0;
  overflow: scroll;
  max-height: 100vh;
  visibility: hidden;
  opacity: 0;
  z-index: 200;
  padding-top: 60px;

  .sidebar {
    &-title {
      display: none;
    }

    &-check-all {
      float: left;
      margin-top: 15px;
      margin-right: 10px;
      padding-bottom: 20px;

      span {
        color: #b1b3b4;
      }

      &.active > span {
        @extend %is-underlined;

        @extend %is-bold;

        color: $body;
      }

      &.divider {
        @extend %is-bold;
      }
    }

    &-no-hotel {
      font-size: 12px;
    }
  }

  .sidebar-section {
    border-bottom: 1px solid $lighter-gray;
  }

  .count-down {
    margin-top: -20px;
  }

  &.is--open {
    opacity: 1;
    visibility: visible;
  }

  @include media-breakpoint-up(sm) {
    @include tn(transform, 200ms);

    position: fixed;
    left: 0;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
    max-height: none;
    transform: translateX(-100%);
    padding-top: 0;

    &.is--open {
      transform: translateX(0);
      z-index: 200;
      overflow: scroll;
      bottom: 0;
      top: 0;
    }

    .sidebar-title {
      display: block;
      text-align: center;
    }

    .tcp-label-button {
      top: -4px;
    }
  }


  @include media-breakpoint-up(lg) {
    position: relative;
    opacity: 1;
    visibility: visible;
    transform: none;
    z-index: 1;
    overflow: auto;
    min-height: calc(100vh - 20px - 30px - #{$header-height});
    align-self: flex-start;
    height: auto;
  }
}
