@import "resources.scss";
.lp-rental-details {
  padding: 0px 20px;
  border-top: 1px solid $border-gray;

  &-inner {
    display: flex;
    width: 100%;
    padding: 20px;

    &-car_fare_description {
      width: 70%;
    }

    &-car_ticket_description {
      width: 30%;
      border-left: 1px solid $lighter-gray;
    }

    &-title {
      @extend %is-font-large;
      font-weight: bold;
      margin-top: 15px;
    }

    &-car_option {
      padding: 10px;
      border-bottom: 1px solid $lighter-gray;
      &:last-child {
        border-bottom: 0px;
      }
    }

    @include media-breakpoint-up(sm) {
      display: flex;
      flex-wrap: wrap;

      .lp-booked-info {
        margin: 0;
      }
    }
  }

}

.icon-info__alignment {
  vertical-align: inherit;
}
