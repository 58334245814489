@import "resources.scss";
.tcp-trip-details {
  &-footer {
    display: flex;
    border-top: 1px solid $grey200;
    width: 100%;

    .button {
      margin-left: auto;
      margin-right: 0;
    }
  }

  &-inner {
    padding: 0 16px 75px;
  }

  &-footer-price strong {
    font-weight: 700;
  }

  @include media-breakpoint-down(sm) {
    &-footer {
      padding: 16px;
      position: fixed;
      bottom: 0;
      width: 100%;
      z-index: 1;
      left: 0;
    }
  }

  @include media-breakpoint-up(sm) {
    padding: 0px 0 0 0;
    border-top: 1px solid $grey200;

    &-inner {
      padding: 0;
    }

    &-footer {
      border-top: 1px solid $grey200;
      padding: 24px;
      margin: 0;
    }

    &-footer-price {
      display: none;
    }
  }
}
