@import "resources.scss";
.lp-trip-sidebar-info {
  background: $lighter-gray;

  &-inner {
    @extend %is-font-medium;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    &-last {
      margin-top: 20px;
    }
  }

  &-logo {
    max-width: 50px;
    max-height: 20px;
  }

  &-content {
    max-width: 210px;
    margin-bottom: 5px;
  }

  &-location {
    margin-bottom: 15px;
  }

  &-row {
    display: flex;
  }

  &-price {
    margin-left: auto;
    font-weight: bold;
  }

  .sidebar-section {
    padding-bottom: 10px;
  }
}
