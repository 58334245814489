@import "resources.scss";
.lp-tariff-options {
  height: 36px;
  .Select-control {
    height: 36px;
    .Select-multi-value-wrapper {
      .Select-value {
        height: 36px;
        padding-top: 5px;
        padding-bottom: 5px;
        line-height: 18px;
        text-overflow: unset;
        white-space: unset;
        display: flex;
        align-items: center;
      }
    }
  }
}
