@import "resources.scss";
.tcp-label-button {
    font-size: 10px;
    line-height: 12px;
    padding: 4px 8px;
    background: $lighter-gray;
    transition: background .2s ease-in-out;
    border-radius: 2px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;

    i {
        font-size: 12px;
        margin-right: 3px;
        vertical-align: middle;
    }

    .tcp-spinner-circle-button {
        width: 12px;
        height: 12px;
        margin-right: 3px;
    }

    &:hover {
        background: rgba($light-gray, 0.5);
    }
}
