/*!
 * Font Awesome Controls
 * fac.css
 * Copyright Maxwel D'souza
 * MIT Licence
 */
.fac {
    user-select: none;
    position: relative;
    display: inline-block;
    vertical-align: middle;
}

.fac label {
    display: inline-block;
    margin: 0em 1em 0em 0em;
    padding-left: 28px;
    cursor:pointer;
    font-weight:inherit;
}

.fac > input + label:before,
.fac > span::after {
  font-family: 'TCP' !important;
  position: absolute;
    left: 0;
    font-size: 20px;
}

.fac > input + label:before {
    transition: opacity 0.3s ease-in-out;
}

.fac input[type=checkbox], .fac input[type=radio] {
    display:none;
}

/********* Behavior ***********/

.fac-checkbox > input + label:before,
.fac-checkbox-round > input + label:before {
    opacity: 1;
}

.fac-checkbox > input:checked + label:before,
.fac > input:checked + label:before {
    opacity: 0;
}

.fac-radio > input + label:before,
.fac-radio-button > input + label:before,
.fac-checkbox-o > input + label:before,
.fac-checkbox-round-o > input + label:before {
    opacity: 0;
}
.fac-radio > input:checked + label:before,
.fac-radio-button > input:checked + label:before,
.fac-checkbox-o > input:checked + label:before,
.fac-checkbox-round-o > input:checked + label:before {
    opacity: 1;
}
/********* Sizes ***********/

.fac-2x {
    font-size: 2em;
}

.fac-radio {
    font-size: 16px;
}

.fac-radio-button {
    font-size: 20px;
}

/********* Colors ***********/

.fac-default > input + label:before,
.fac-default > span::after {
    color: grey;
}
.fac-primary > input + label:before,
.fac-primary > span::after {
    color: $blue;
}
.fac-success > input + label:before,
.fac-success > span::after {
    color: #5cb85c;
}
.fac-info > input + label:before,
.fac-info > span::after {
    color: #5bc0de;
}
.fac-warning > input + label:before,
.fac-warning > span::after {
    color: #f0ad4e;
}
.fac-danger > input + label:before,
.fac-danger > span::after {
    color: #d9534f;
}

/********* Controls ***********/

.fac-checkbox > span::after {
    content: $icon-check_box; /* checked */
}
.fac-checkbox > input[type=checkbox] + label:before {
    content: $icon-check_box_outline_blank;
}

.fac-checkbox-round > span::after {
    content: "\f058";
}
.fac-checkbox-round > input[type=checkbox] + label:before {
    content: "\f111";
}

.fac-checkbox-o > span::after {
    content: $icon-check_box_outline_blank;
    font-size: 20px;
}
.fac-checkbox-o > input[type=checkbox] + label:before {
  content: $icon-check_box;
}

.fac-checkbox-round-o > span::after {
    content: "\f10c";
}
.fac-checkbox-round-o > input[type=checkbox] + label:before {
    content: "\f05d";
}

.fac-radio > span::after {
    content: $icon-radio_button_unchecked;
}
.fac-radio > input[type=radio] + label:before {
    content: $icon-radio_button_checked;
    top: 0px;
}

.fac-radio-button-check > span::after {
  content: $icon-check;
  color: $light-gray;
}
.fac-radio-button-check > input[type=radio] + label:before {
  content: $icon-check;
  color: $blue;
  top: 0px;
}

.fac-radio-button-ban > span::after {
  content: $icon-disabled;
  color: $light-gray;
}
.fac-radio-button-ban > input[type=radio] + label:before {
  content: $icon-disabled;
  color: $red;
  top: 0px;
}



.fac-radio {
  font-size: 16px;

  & > input + label:before, & > span::after {
    font-size: 18px;
    line-height: 20px;
  }

  label {
    @include body;
    padding-left: 26px;

    &:before {
      font-size: 18px;
      line-height: 20px;
    }

    &.radio-label__spacing {
      margin-right: 50px;
    }
  }
}

.fac-default > input + label:before,
.fac-default > span::after {
  color: $body;
}
