@import "resources.scss";

// overwrite rc-slider
.rc-slider {
    margin: 0 auto 10px;
    width: calc(100% - 20px);

    @include media-breakpoint-up(sm) {
      width: calc(100% - 22px);
    }

}

.rc-slider, .rc-slider-track {
    height: 4px;
}

.rc-slider-track {
  .sidebar-inbound & {
    background-color: $red;
  }

  .sidebar-outbound & {
    background-color: $blue;
  }

  .sidebar-hotels & {
    background-color: $body;
  }
}

.rc-slider-handle {
    border: 1px solid $light-gray;
    position: absolute;
    margin-top: -6px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    border-radius: 50%;
    background-color: white;
    z-index: 0;

    &:hover {
        background-color: #dbdbdb;
    }

    &-active {
        &:active {
            border-color: tint($blue, 20%);
            box-shadow: 0 0 5px tint($blue, 20%);
        }
    }

    @include media-breakpoint-up(sm) {
        margin-top: -9px;
        width: 22px;
        height: 22px;
    }
}
