//  ================================================
//  Sidebar
//  ================================================

.sidebar {
  background-color: white;
  min-height: calc(100vh - #{$header-height});
  width: 100%;

  &-title {
    font-weight: bold;
    color: #fff;
    line-height: 47px;
    text-align: center;
    position: relative;
    margin-bottom: 12px;

    &:before {
      content: "";
      @include pos(n n -12px 50%);
      margin-left: -17px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 12px 14px 0 14px;
      border-color: transparent;
      // z-index: 1;
    }
  }

  &-inner {
    padding: 20px 15px 30px 15px;
  }

  &-button {
    margin-top: 30px;
    .button {
      width: 215px;
      min-width: 160px;
      margin: auto;
    }
  }

  &-reset {
    position: absolute;
    top: 0;
    right: 0;
  }

  &-edit {
    margin-top: 8px;
  }

  &-trip-details {
    padding-top: 10px;
    position: relative;
  }


  &-check-all {
    @extend %is-font-small-strict;
    float: left;
    margin-top: 15px;
    margin-right: 10px;
    padding-bottom: 20px;
    span {
      color: #b1b3b4;
    }
    &.active > span {
      @extend %is-underlined;
      @extend %is-bold;
      color: $body;
    }
    &.divider {
      @extend %is-bold;
    }
  }

  // &-count-down {
  //   &.sidebar-section {
  //     flex-direction: row;
  //   }
  //   background-color: $blue-light;
  //   white-space: nowrap;
  //   overflow: hidden;
  //   height: 60px;
  //   text-align: center;
  //   margin-top: -20px;
  //   padding-top: 25px;
  //   z-index: -1;
  //   transition: background-color 2s ease;

  //   &-danger {
  //     background-color: $red-light;
  //   }

  //   &-caption {
  //     font-size: 15px;
  //   }

  //   &-timer {
  //     h2 {
  //       display: inline-block;
  //       font-size: 18px;
  //     }
  //   }
  // }

  &-badge {
    background: $gray;
    bottom: 0;
    color: white;
    height: 60px;
    line-height: 60px;
    padding-left: 26px;
    position: absolute;
    width: 100%;
    text-transform: uppercase;
    a {
      color: white;
    }
    &:before {
      @include position(center left 0);
      content: "";
      height: 0;
      border-style: solid;
      border-width: 14px 0 14px 15px;
      border-color: transparent transparent transparent white;
      width: 0;
    }
  }

  &-filter-only {
    @extend %is-font-medium;
    color: $gray;
    white-space: nowrap;
    margin: 0;
  }

  @include media-breakpoint-up(md) {
    flex: 0 0 $sidebar-width;
    width: $sidebar-width;
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    min-height: 0;

    &-title {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
  }
}


.sidebar {
  &-datetime-outbound, &-datetime-inbound {
    text-align: center;
    .sidebar-section {
      padding-left: 0;
      padding-right: 0;
      @include media-breakpoint-up(sm) {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
}

.sidebar-widget {

  @include media-breakpoint-up(sm) {
    @include box-shadow;
    min-height: 600px;
    background: rgba(255,255,255,0.6);

    &, .sidebar-inner {
      width: $widget-width;
    }

  }
}

.sidebar-concierge {
  @include media-breakpoint-up(sm) {
    max-height: calc(100vh - 130px);
    .sidebar-inner {
      overflow: auto;
    }
  }
}

.sidebar-inbound, .sidebar-datetime-inbound, .sidebar-search {
  .sidebar-title {
    background-image: linear-gradient(125deg, #D53526 0%, #E2392A 49%, #F03D2F 100%);
    &:before {
      border-top-color: #DD3728;
    }
  }
  .sidebar-route-price {
    @extend %is-bold;
  }
}

.sidebar-outbound, .sidebar-route-outbound, .sidebar-datetime-outbound {
  .sidebar-title {
    background-image: linear-gradient(45deg, #0172B1 0%, #036297 100%);
    &:before {
      border-top-color: #0172B1;
    }
  }
}

.sidebar-hotels, .sidebar-checkout, .sidebar-passenger, .sidebar-concierge {
  .sidebar-title {
    background-image: linear-gradient(-85deg, #5B5B5B 0%, #454246 100%);
    &:before {
      border-top-color: #454246;
    }
  }
}

.sidebar-route-outbound, .sidebar-route-inbound {
  margin-top: 0;
}

.sidebar-outbound, .sidebar-inbound, .sidebar-checkout {
  @include media-breakpoint-up(sm) {
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
    display: flex;
    opacity: 1;
    z-index: $z1;
  }
}

.sidebar-checkout {
  position: sticky;
  bottom: 60px;
  top: auto;
  align-self: flex-end;
  min-height: calc(100vh - 20px - 30px - #{$header-height});
}

.hide-slider-left-regulator {
  .rc-slider-handle-2 {
    display: none;
  }
}

.hide-slider-right-regulator {
  .rc-slider-handle-1 {
    display: none;
  }
}

