@import "resources.scss";
.tcp-search-bar-date-time {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  &-item {
    width: calc(50% - 6px);
    text-align: right;

    .tcp-link {
      display: inline-block;
    }
  }

  &-link {
    text-align: right;
    position: relative;
  }
}
