@import "resources.scss";
.lp-be-notification-bar {
  @include tn(opacity visibility);

  max-width: 330px;
  top: 16px;
  right: 16px;
  background: $black50;
  padding: 12px 32px 12px 12px;
  position: fixed;
  z-index: 200;
  border: 1px solid $blue;
  border-radius: 5px;
  opacity: 0;
  visibility: hidden;

  &-inner {
    display: flex;
    @include caption;

    svg {
      margin-right: 8px;
      width: 20px;
      flex-shrink: 0;
      path {
        fill: $blue;
      }
    }

    h1,
    h2 {
      @include body;
      margin-bottom: 4px;
      font-weight: 500;
    }

    a {
      @include body;

      font-weight: 700;
      color: $blue;

      &:hover {
        color: $blue900;
      }
    }

    p:last-child {
      margin-bottom: 0;
    }
  }

  &-close {
    position: absolute;
    top: 12px;
    right: 12px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  &.is--open {
    opacity: 1;
    visibility: visible;
  }
  
  @include media-breakpoint-up(md) {
    max-width: 400px;
  }
}

// #map-container .lp-be-notification-bar {
//   margin-top: 60px;
//   margin-bottom: -40px;
//   z-index: 1;
//   padding: 5px 24px;

//   @include media-breakpoint-up(md) {
//     left: 480px;
//     position: absolute;
//     right: 0;
//     margin-top: 70px;
//     margin-bottom: 0;
//   }
// }
