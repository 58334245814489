@import "resources.scss";
.lp-trip-sidebar-selection-info {
  background: $lighter-gray;

  &-inner {
    @extend %is-font-medium;
  }

  &-logo {
    max-width: 100px;
    max-height: 30px;
    position: absolute;
    top: 10px;
    right: 10px;
  }

  &-dates {
    margin-bottom: 5px;
  }

  &-location {
    margin-bottom: 15px;
  }

  &-row {
    display: flex;
  }

  &-price {
    margin-left: auto;
    font-weight: bold;
  }

  .sidebar-section {
    padding-bottom: 10px;
  }
}
