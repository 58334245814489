@import "resources.scss";
.tcp-leg {
    @include caption;
    width: 100%;
    position: relative;
    display: flex;
    padding-top: 16px;

    &-item {
        position: relative;
        display: flex;
        flex-wrap: wrap;

        &-inbound {
            padding-left: 16px;
            flex-wrap: nowrap;

            svg {
                display: none;
            }
        }
    }

    &-time {
        @include caption;
        font-weight: 500;
        white-space: nowrap;
        text-align: center;
    }

    &-location {
        @include body;

        font-weight: 500;
        margin-bottom: 16px;

        &-label {
            @include body;

            margin-right: 16px;
        }
    }

    &-timetable {
        padding-left: 16px;
    }

    &-waiting {
        width: 100%;
        display: flex;
        margin-top: 10px;
        padding-left: 44px;

        &-inner {
            @extend %is-font-medium;

            border: 1px solid $lighter-gray;
            padding: 5px 15px;
            text-align: center;
        }
    }

    &-tickets {
        margin-left: 16px;
        width: 100%;
        padding: 25px 15px;
        border: 1px solid $border-gray;

        &-operator {
            @extend %is-font-medium;

            display: flex;
            justify-content: space-between;
        }
    }

    &-button {
        padding: 7px 8px;
        min-width: 90px;
    }

    &-button-group {
        display: flex;
        padding: 20px 0 0 44px;
        width: 100%;

        button {
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
        }

        button + button {
            margin-left: 2px;
            border-radius: 0;
            border-top-right-radius: 2px;
            border-bottom-right-radius: 2px;
        }

        &:first-child {
            padding: 20px 0 25px 44px;
            border-top: 1px dashed $border-gray;
        }
    }

    &-expand {
        @extend %is-font-medium;
        color: $gray;
        white-space: nowrap;
        margin: 0;
    }

    &-expand-icon {
        @include make-icon($icon-angle-up);
        vertical-align: sub;
        margin-right: 5px;
    }

    &-status {
      @extend %is-font-small;

      border: 1px solid $blue;
      border-radius: 3px;
      font-weight: bold;
      padding: 4px 6px;
      margin-left: 120px;
      display: inline-flex;
      align-items: center;
      margin-top: 25px;

      i {
        color: $blue;
        margin-right: 5px;
      }
    }

    &.is--collapsed {
        .tcp-leg-expand-icon {
            @include make-icon($icon-angle-down);
        }
    }

    @include media-breakpoint-up(md) {
        border-bottom: 1px dashed $border-gray;
        padding-top: 0;
        padding-left: 24px;
        padding-right: 24px;

        &-time, &-timetable, &-tickets {
            padding-top: 24px;
        }
        &-item {
            display: flex;
            width: 100%;
            padding-left: 20px;

            &-inbound { 
                svg {
                    display: inline-block;
                }

                .tcp-leg-location {
                    padding-left: 20px;
                }
            }

        }

        &-item-transfer {
            min-height: 0;
            padding-bottom: 10px;
            padding-top: 10px;

            .select {
                display: inline-block;
                width: auto;
            }
        }

        &-time {
            @include body;
            width: 40px;
            white-space: wrap;
            flex-shrink: 0;
        }

        &-timetable {
            flex-grow: 1;
            margin-right: auto;
            padding-left: 20px;
        }

        &-waiting {
          padding-left: 0;
        }

        &-tickets {
            width: 296px;
            flex-shrink: 0;
            border: 0;
            padding-bottom: 20px;
            position: relative;
            margin: 0;
        }

        &-inbound {
            position: relative;
            padding-top: 0;
            padding-bottom: 24px;
            .tcp-leg-time {
                padding-top: 0;
            }

            &::after {
                content:"";
                height: 100%;
                position: absolute;
                right: 319px;
                border-left: 1px solid $grey200;
                top: 0;
            }
        }

        &-inbound, &-last {
            border-bottom: 0;
        }

        &-button-group{
          padding: 15px 0 0 0;

          &:first-child {
            border: 0;
            padding: 0 0 15px 0;
          }
        }
        

        &-main {
            .tcp-leg-tickets {
                border-left: 1px solid $grey200;
                padding-left: 30px;
            }
        }
    }

    @include media-breakpoint-up(xl) {
        &-timetable {
            flex-basis: 55%;
        }
    }
}
