@import "resources.scss";
.tcp-trip-routes {
  @include caption;
  padding-bottom: 4px;
  display: flex;
  align-items: center;

  &-time {
    font-weight: 700;
  }

  &-legs {
    position: relative;
    display: flex;
    flex: 1;
    padding: 0 12px;
  }

  &-item {
    display: inline-block;
    overflow: hidden;
    position: relative;
  }

  &-icon-dep {
    path {
      fill: $blue;
    }
  }

  &-icon-arr {
    path {
      fill: $red;
    }
  }

  @include media-breakpoint-up(sm) {
    @include body;
    flex-wrap: wrap;
    padding-bottom: 12px;

    &-time {
      width: 50%;
      padding-bottom: 12px;

      &:last-child {
        text-align: right;
      }
    }

    &-legs {
      width: 100%;
      padding: 0;
      order: 3;
    }

    &-item-public {
      color: $light-gray;
      .tcp-leg-waypoint {
        &:before {
          background-color: $light-gray;
        }
        &:after {
          border-color: transparent transparent transparent $light-gray;
        }
        &-icon {
          color: $light-gray;
          border-color: $light-gray;
        }
      }
    }
  }
}
