//  ================================================
//  Sidebar Section
//  ================================================

.sidebar-section {
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  padding-right: 15px;

  //TODO: refactore to has-no-toggle
  &.no-toggle {
    padding: 25px;
  }

  &:last-child {
    border-bottom: 0;
  }

  &-title {
    font-weight: bold;
    cursor: pointer;
    padding: 15px 0;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-passengers-link {
    @extend %is-font-small;

    text-align: right;

    span {
      @extend %is-underlined;

      cursor: pointer;
    }
  }

  &-indicator {
    display: none;
  }

  &-inner {
    display: block;
    margin-bottom: 20px;
    position: relative;

    &.has-spacing {
      padding-top: 15px;
    }

    .fac {
      padding: 4px 0;
    }
  }

  &-headline {
    @extend %is-font-medium;

    margin-top: 10px;
  }

  &-row {
    &:after, &:before {
      display: table;
      content: " ";
    }

    &:after {
      clear: both;
    }
  }

  &-col {
    float: left;
    min-height: 1px;
    position: relative;
    width: 50%;

    &:first-child {
      border-right: 1px solid $light-gray;
    }
  }

  &-action-link {
    color: $gray;
    display: inline-block;

    &:first-child:after {
      @extend %is-bold;

      content: "|";
      color: $body;
      padding: 0 5px;
    }

    &-primary {
      @extend %is-bold;

      @extend %is-underlined;

      color: $body;
    }
  }

  &.is-open {
    .sidebar-section {
      &-inner {
        display: block;
      }

      &-indicator {
        transform: rotate(90deg);
      }
    }
  }

  &.is-first {
    .sidebar-section-title {
      padding-top: 0;
    }
  }

  @include media-breakpoint-up(sm) {
    flex-shrink: 0;

    &-inner {
      display: none;
    }

    &-indicator {
      display: inline-block;

      @include make-icon($icon-angle-right);

      line-height: 0.8;
      transition: transform 0.2s ease-in;

      &-disabled {
        color: $light-gray;
      }
    }

    &.no-toggle {
      padding: 16px;
    }
  }
}
