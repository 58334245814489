@import "resources.scss";
.lp-rental {
  &-title {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    &-name {
      margin-bottom: 0;
    }
    &-group {
      @extend %is-font-small;
      color: $gray;
    }
  }

  &-pickup {
    display: flex;
    margin-bottom: 5px;

    &-label {
      @extend %is-bold;
      @extend %is-font-medium;
    }
    &-value {
      @extend %is-font-medium;
    }

    &:last-child {
      margin-bottom: 0px;
    }
  }
}

.tcp-rental {
  &-image {
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 220px;
    background: $gray-background url(../../../assets/img/backgrounds/rental_placeholder.svg) no-repeat center / 75px;
  }

  @include media-breakpoint-up(sm) {
    cursor: default;
    border-radius: 5px;

    &-image {
      @include make-percent-col(25);
      height: auto;
      max-height: 100%;
      background-size: 50px;
      background-color: white;

      img {
        object-fit: contain;
      }
    }
  }
}
